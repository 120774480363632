import React from 'react';
import { useGetAllStoreLocationsQuery } from '@generated-graphql';

const useStoreLocation = () => {
  const { data } = useGetAllStoreLocationsQuery();
  const [storeLocations, setStoreLocations] = React.useState<Array<any>>([]);

  React.useEffect(() => {
    if (data && data.getAllStoreLocations) {
      setStoreLocations(data.getAllStoreLocations);
    }
  }, [data]);

  const getStoreLocationName = (storeCode: string) => {
    const foundLocation = storeLocations.find(
      (location: any) => location.storeCode === storeCode
    );
    return foundLocation ? foundLocation?.displayName : null;
  };

  const getStoreLocationSlug = (storeCode: string) => {
    const foundLocation = storeLocations.find(
      (location: any) => location.storeCode === storeCode
    );
    return foundLocation ? foundLocation?.slug : null;
  };

  return { getStoreLocationName, getStoreLocationSlug, storeLocations };
};

const StoreLocationContext = React.createContext(
  {} as ReturnType<typeof useStoreLocation>
);

function useStoreLocationContext() {
  return React.useContext(StoreLocationContext);
}

function StoreLocationProvider({ children }: { children: React.ReactNode }) {
  const StoreLocation = useStoreLocation();
  return (
    <StoreLocationContext.Provider value={StoreLocation}>
      {children}
    </StoreLocationContext.Provider>
  );
}

export { StoreLocationProvider, useStoreLocationContext };
