import fetch from 'isomorphic-fetch';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import introspectionQueryResultData from '@src/generated/fragmentTypes.json';

export const client = new ApolloClient({
  link: from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    createHttpLink({
      uri: process.env.GATSBY_GRAPHQL_URL,
      credentials: 'include',
      fetch,
    }),
  ]),
  cache: new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
  }),
});
