// eslint-disable
import React from 'react';
import { ApolloProvider } from '@apollo/client';

import { client } from './src/apollo/client';
import { AuthProvider } from './src/contexts/AuthContext';
import { StoreLocationProvider } from './src/contexts/StoreLocationContext';


export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <AuthProvider>
      <StoreLocationProvider>
        {element}
      </StoreLocationProvider>
    </AuthProvider>
  </ApolloProvider>
);
