const logErrors = <T extends Error>(specificError: T) => {
  // eslint-disable-next-line
  console.error(specificError, specificError.message);
};

export const throwDevelopmentError = <T extends Error>(specificError: T) => {
  if (process.env.NODE_ENV === 'development') {
    throw specificError;
  } else {
    logErrors(specificError);
  }
};

export class ApplicationError extends Error {}
