import React, { useEffect } from 'react';
import { throwDevelopmentError, ApplicationError } from '@utilities/errors';
import {
  useLoginMutation,
  useLogoutMutation,
  useIsLoggedInQuery,
  LoginInput,
  LoggedInState,
  IsLoggedInDocument,
  LoginMutation,
  UserRole,
  User,
} from '@generated-graphql';

type AuthProviderProps = {
  children: React.ReactNode;
};

const userModel = {
  fromResponse: (response: LoginMutation) => {
    const user = response.login?.user;
    if (!user) {
      throw new ApplicationError('User not found');
    }
    return userModel.fromUser(user);
  },

  fromUser: (user: User) => ({
    isGlobalAdmin: user.role === UserRole.GlobalAdmin,
    isAdminForStoreLocationId: (storeLocationId: number) =>
      user.role === UserRole.GlobalAdmin ||
      (user.role === UserRole.StoreAdmin &&
        Number(user.storeLocationId) === Number(storeLocationId)),
  }),
};

const useNetworkLoginLayer = () => {
  const [loginMutation] = useLoginMutation();
  const login = (
    { email, password }: LoginInput,
    loginCallback: (response: LoginMutation | null | undefined) => void
  ) =>
    loginMutation({
      variables: {
        input: {
          email,
          password,
        },
      },
      refetchQueries: [
        {
          query: IsLoggedInDocument,
        },
      ],
      update: (_, { data: loginUserData }) => {
        const loggedInState = loginUserData?.login?.loggedIn;
        if (loggedInState === LoggedInState.LoggedIn) {
          loginCallback(loginUserData);
        } else throw new ApplicationError('Login unsuccessful');
      },
    });
  const [logoutMutation] = useLogoutMutation();
  const logout = (logoutCallback: () => void) =>
    logoutMutation({
      update: (_, { data: loginUserData }) => {
        const loggedInState = loginUserData?.logout?.loggedIn;
        if (loggedInState === LoggedInState.LoggedOut) {
          logoutCallback();
        } else
          throwDevelopmentError(new ApplicationError('Logout unsuccessful'));
      },
    });
  const { data, loading } = useIsLoggedInQuery();
  const isLoggedIn = data?.isLoggedIn?.loggedIn === LoggedInState.LoggedIn;
  return { login, logout, isLoggedIn, loading, user: data?.isLoggedIn.user };
};

function useAuth() {
  const {
    login,
    logout,
    isLoggedIn,
    loading,
    user: _user,
  } = useNetworkLoginLayer();
  const [user, setUser] = React.useState<ReturnType<
    typeof userModel.fromResponse
  > | null>(_user ? userModel.fromUser(_user) : null);

  useEffect(() => {
    if (_user) {
      setUser(userModel.fromUser(_user));
    }
  }, [_user]);

  const loginUser = (
    { email, password }: LoginInput,
    loginCallback: () => void
  ) => {
    login({ email, password }, (response) => {
      if (response) {
        setUser(userModel.fromResponse(response));
      }
      loginCallback();
    });
  };

  const logoutUser = (logoutCallback: () => void) => {
    if (!isLoggedIn) {
      throwDevelopmentError(
        new ApplicationError('logout called when user already logged out')
      );
    } else logout(logoutCallback);
  };

  return {
    isLoggedIn,
    loginUser,
    logoutUser,
    loading,
    user,
  };
}

const AuthContext = React.createContext<ReturnType<typeof useAuth>>({
  isLoggedIn: false,
  loginUser: () => {
    throw new Error('Auth not properly configured, invalid loginUser call');
  },
  logoutUser: () => {
    throw new Error('Auth not properly configured, invalid logoutUser call');
  },
  loading: false,
  user: {
    isAdminForStoreLocationId: () => false,
    isGlobalAdmin: false,
  },
});

function AuthProvider({ children }: AuthProviderProps) {
  const auth = useAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

function useAuthContext() {
  return React.useContext(AuthContext);
}

export { AuthProvider, useAuthContext };
